$( document ).ready(function() {

    console.log(jQuery);

    $('a[href^="#"]').click(function(){
        var id = $(this).attr("href");

        $('html, body').animate({
            scrollTop:$(id).offset().top - 150
        }, 'slow');
        return false;
    });


    var lastScrollTop = 0;
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        var st = $(this).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            $('.header').addClass('scrolled');
        } else {
            $('.header').removeClass('scrolled');
        }

        if (st > lastScrollTop){
            $('.header').addClass('hideMore');
        } else {
            $('.header').removeClass('hideMore');
        }
        lastScrollTop = st;
    });

});
